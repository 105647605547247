<template>
  <top-bar title="Home" />
  <div class="bg-gray-300 w-full h-full">
    <div
      class="pt-20 grid-template grid gap-4 p-4 w-full items-center justify-center justify-self-center"
    >
      <div class="grid-cell">
        <router-link to="/costs" class="grid-cell-content" >
          <icon
            name="currency-dollar"
            size="40"
            class="w-full m-2"
          />
          <p class="text-center text-2xl font-bold">Custos</p>
        </router-link>
      </div>
      <div class="grid-cell">
        <router-link to="/users" class="grid-cell-content" >
          <icon
            name="user-group"
            size="40"
            class="w-full m-2"
          />
          <p class="text-center text-2xl font-bold">Vendedores</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { auth } from '@/services'
import TopBar from '@/components/TopBar'
import Icon from '@/components/Icon'

export default {
  components: { TopBar, Icon },

  setup (props) {
    const store = useStore()
    const costs = computed(() => store.state.costs)

    onMounted(async () => {
      // await store.dispatch('getAllCosts')
    })

    async function logout () {
      await auth.logout()
    }

    return {
      logout,
      costs
    }
  }
}
</script>

<style scoped>
.grid-template {
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-template-rows: 1fr;
}

.grid-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-cell-content {
  @apply flex flex-col items-center justify-center bg-veturi-main text-veturi-white w-40 h-36 rounded-2xl;
}
</style>
